import React from 'react';
import Seo from '../components/Seo';
import ComponentList from '../components/ComponentList';

export default ({pageContext, location}) => {
	const {seo = {}, components = []} = pageContext;

	const metaTitle = seo.metaTitle || 'Genexa';
	const openGraphTitle = seo.openGraphTitle || 'Genexa';
	const twitterTitle = seo.twitterTitle || 'Genexa';

	return (
		<React.Fragment>
			<Seo
				metaTitle={metaTitle}
				metaDescription={seo.metaDescription}
				metaKeywords={seo.metaKeywords}
				openGraphTitle={openGraphTitle}
				openGraphDescription={seo.openGraphDescription}
				openGraphImage={seo.openGraphImage}
				twitterTitle={twitterTitle}
				twitterDescription={seo.twitterDescription}
				twitterImage={seo.twitterImage}
				pathname={location.pathname}
			/>
			<ComponentList components={components} />
		</React.Fragment>
	);
};
